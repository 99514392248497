import vrt from '../assets/img/game_BA/10/d10.jpg'
import kuhinja from '../assets/img/game_BA/10/k10.jpg'
import outItem1 from '../assets/img/game_BA/10/vrt/1.png'
import outItem2 from '../assets/img/game_BA/10/vrt/2.png'
import outItem3 from '../assets/img/game_BA/10/vrt/3.png'
import outItem4 from '../assets/img/game_BA/10/vrt/4.png'
import outItem5 from '../assets/img/game_BA/10/vrt/5.png'
import p1 from '../assets/img/game_BA/10/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_BA/10/kuhinja/3pro.webp'
import k2 from '../assets/img/game_BA/10/kuhinja/4pro.png'
import k3 from '../assets/img/game_BA/10/kuhinja/5997381346917-drOetker-Kakao-za-kolace-1.png'
import k4 from '../assets/img/game_BA/10/kuhinja/400521000349-drOetker-Kvasac-instant-1.png'
import k5 from '../assets/img/game_BA/10/kuhinja/van.jpg'
import k6 from '../assets/img/game_BA/10/kuhinja/torta trostruki uzitak.png'
import k7 from '../assets/img/game_BA/10/kuhinja/r1.png'
import k8 from '../assets/img/game_BA/10/kuhinja/recept2.png'
import k9 from '../assets/img/game_BA/10/kuhinja/rec1.png'
import k10 from '../assets/img/game_BA/10/kuhinja/Torta u obliku zeca.png'

const day_10 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "75deg", pitch: "-31.7deg" },
                size: { width: 24, height: 27 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-21.8deg", pitch: "-20.5deg" },
                size: { width: 17, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-78.8deg", pitch: "-16deg" },
                size: { width: 19, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-146.5deg", pitch: "-26deg" },
                size: { width: 30, height: 36 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-69.2deg", pitch: "-35.5deg" },
                size: { width: 24, height: 28 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-11deg", pitch: "-13.5deg" },
                size: { width: 45, height: 65 },
                product_title: 'Crvena boja za kolače',
                product_description: 'Crvena boja u plastičnoj tubici olakšava pravilno doziranje i ponovno zatvaranje. Može se ponovno koristiti ako ju nakon uporabe držite u hladnjaku.',
                product_image: k1,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/cimet-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "231.2deg", pitch: "-35deg" },
                size: { width: 42, height: 85 },
                product_title: 'Aroma vanilije u bočici',
                product_description: 'S Dr. Oetker aromom vanilije vaši će kolači i deserti jednostavno postati zavodljiviji.',
                product_image: k2,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/arome/aroma-vanilije-u-bocici'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-69.5deg", pitch: "-21.8deg" },
                size: { width: 85, height: 112 },
                product_title: 'Kakao za kolače',
                product_description: 'Dr. Oetker Kakao za kolače svojom punoćom i intentizvnom tamnom bojom, savršen je dodatak čokoladnim tijestima i kremama za kolače kao i za dekoraciju.',
                product_image: k3,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/kakao-za-kolace/kakao-za-kolace'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-170deg", pitch: "-7.5deg" },
                size: { width: 105, height: 115 },
                product_title: 'Kvasac',
                product_description: 'Dr. Oetker Kvasac idealan je suhi kvasac za pripremu slatkih i slanih dizanih tijesta svih vrsta.',
                product_image: k4,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/kvasac/instant-kvasac'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-33.2deg", pitch: "-8.6deg" },
                size: { width: 85, height: 106 },
                product_title: 'Creative puding vanilija kardamom',
                product_description: 'Pripremite se za neočekivani spoj dva uzbudljiva okusa – vanilija i kardamom! Creative pudinzi osmišljeni su jedinstvenom kombinacijom dvaju suprotnih okusa koji će vas očarati svježinom uz dobro poznatu teksturu pudinga.',
                product_image: k5,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/creative-pudding/creative-pudding-vanilija-kadamom'
            },
            {
                id: 6,
                trigger_image: ' ',
                position: { yaw: "14.5deg", pitch: "-20.4deg" },
                size: { width: 130, height: 93 },
                product_title: 'Torta trostruki užitak',
                product_description: 'Torta Trostruki užitak ukusna je poslastica koja spaja tri neodoljiva sloja savršenstva. Sa sočnim biskvitom od oraha kao osnovom, prelivenim kremastim slojem od vanilije i bogatim čokoladnim preljevom, ova torta će zadovoljiti sve vaše čokoladne želje. Jednostavna za pripremu, a osvojit će srca svih ljubitelja slatkog užitka',
                product_image: k6,
                product_url: 'https://bih.oetker-recepti.com/torta-trostruki-uzitak/'
            },
            {
                id: 7,
                trigger_image: ' ',
                position: { yaw: "-192deg", pitch: "-50.6deg" },
                size: { width: 240, height: 170 },
                product_title: 'Jaja od granole',
                product_description: 'Ova hrskava jaja od granole jednostavno se pripremaju i ne smiju nedostajati na blagdanskom stolu.',
                product_image: k7,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/jaja-od-granole'
            },
            {
                id: 8,
                trigger_image: ' ',
                position: { yaw: "-102.5deg", pitch: "-33.4deg" },
                size: { width: 120, height: 120 },
                product_title: 'Američki kolačići',
                product_description: 'Ovi slatki američki keksi od ukusnog miješanog tijesta, ukrašeni poput pilića, pravi su mamac za poglede na svečanom uskrsnom stolu.',
                product_image: k8,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/americki-kolacici'
            },
            {
                id: 9,
                trigger_image: ' ',
                position: { yaw: "-56.5deg", pitch: "-29.5deg" },
                size: { width: 172, height: 155 },
                product_title: 'Rolada od limuna',
                product_description: 'Ova lagana biskvitna rolada sa sočnim nadjevom od limuna, kiselog i tučenog vrhnja bit će svima fina.',
                product_image: k9,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/rolada-od-limuna'
            },
            {
                id: 10,
                trigger_image: ' ',
                position: { yaw: "-19.8deg", pitch: "-41deg" },
                size: { width: 156, height: 200 },
                product_title: 'Torta u obliku zeca',
                product_description: 'Efektna, razigrana i ukusna torta - idealna za obiteljsko okupljanje ili kao poklon za Uskrs',
                product_image: k10,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/torta-od-vanilije-u-obliku-zeca'
            }
        ]
    },
]

export default day_10